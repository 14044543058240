import { Button, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import Progress from "react-progress-2";

const { Option } = Select;

export default function ScrapDataSync() {
  const location = useLocation();
  useBreadCrumb("Scrap Data Sync", location.pathname, "", "add");
  const [scrapData, setScrapData] = useState([]);
  const [pageKey, setPageKey] = useState("UK");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      Progress.show();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/get-scarp-json?country=${pageKey}`
      );
      const data = await response.json();
      setScrapData(data.data);
      Progress.hide();
    };

    fetchData();
  }, [pageKey, refresh]);

  const handleDelete = async (id) => {
    Progress.show();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/get-scarp-json/delete-uni-object/${id}?country=${pageKey}`
    );
    const data = await response.json();
    console.log(data);

    message.success(data.message);
    setRefresh(!refresh);
    Progress.hide();
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Sync the scrap data | <span>Scrap Data Sync</span>
        </p>
        <div className="select_op">
          <label>Select Country</label>
          <Select
            placeholder="Select page key"
            allowClear
            size="large"
            style={{ width: 250 }}
            onChange={(value) => {
              setPageKey(value);
            }}
            value={pageKey}
          >
            <Option value={"AU"}>Australia</Option>
            <Option value={"CA"}>Canada</Option>
            <Option value={"NZ"}>New Zealand</Option>
            <Option value={"UK"}>United Kingdom</Option>
          </Select>
        </div>
        {scrapData.map((data) => (
          <div
            className="data_row"
            key={data.id}
            style={{
              marginBottom: 10,
            }}
          >
            <div>
              <Link to={`/settings/scraping-data-sync/${pageKey}/${data.id}`}>
                {data.name}
              </Link>
            </div>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleDelete(data.id);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
